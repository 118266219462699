
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import MainGrid from '../components/styling/MainGrid'

const NotFoundPage = () => (
  <Layout>
    <MainGrid>
      <TextContainer>

      <h1>NOT FOUND</h1>
      <p>"Uh oh, something went wrong. This page doesn't exist."</p>
      </TextContainer>
    </MainGrid>
  </Layout>
)

export default NotFoundPage


const TextContainer = styled.div`

text-align: center;
margin: 100px auto;
`